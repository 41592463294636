import {
  ArrowForward,
  Close,
  InfoOutlined,
  MailOutline,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { PropsWithChildren, useState } from "react";

export interface AppInfoCardProps {
  /** Name of the app. */
  name: string;
  /** Description of the app. */
  description: string;
  /** URL to a logo. If not set, no logo is displayed. */
  logo?: string;
  /** URL to the app. When a user clicks the "open" button, this is where the browser goes. */
  url: string;
  /** Email address to reach out to about the app. When a user clicks the email button, an email dialog is opened with this email address as the recipient */
  email?: string;
  /** If set, the subject of the email opened.  */
  emailSubject?: string;
  /** The text displayed in the "info text" popover. */
  infoText: string;
  /** The individual table entries displayed in the center part of the card. */
  tableEntries: {
    /** Label of an table entry, displayed on the left side. */
    label: string;
    /** Value of an table entry, displayed on the right side. */
    value: string;
    /** If set, clicking on the table entry will open this URL. */
    url?: string;
    /** If set, clicking on the table entry will open an email to this recipient. */
    email?: string;
  }[];
  cardColor?: string;
}

export const AppInfoCard: PimoReactComponent<AppInfoCardProps> = ({
  name,
  description,
  logo,
  url,
  email,
  emailSubject,
  infoText,
  tableEntries,
  cardColor,
}) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            background: cardColor ?? "#122B54",
            color: "#fff",
            width: "100%",
            height: "154px",
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
          }}
        >
          <Box
            sx={{
              height: "36px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 1,
              paddingTop: 2,
              paddingRight: 2,
              paddingBottom: 5,
            }}
          >
            {email && (
              <a
                href={`mailto:${email}?subject=${emailSubject ?? ""}`}
                style={{ color: "#fff" }}
              >
                <MailOutline sx={{ cursor: "pointer" }} />
              </a>
            )}
            <InfoOutlined
              sx={{ cursor: "pointer" }}
              onClick={() => setIsInfoModalOpen(true)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            {!logo && (
              <Typography fontSize={30} sx={{ height: "40px" }}>
                {name}
              </Typography>
            )}
            {logo && (
              <img src={logo} style={{ maxWidth: "133px", height: "40px" }} />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              padding: 2,
            }}
          >
            <Typography fontSize={16}>{description}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#ffffff",
            color: "#000",
            width: "100%",
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            paddingTop: 1,
            paddingBottom: 2,
            flexGrow: 1,
          }}
        >
          <table width="100%" cellSpacing="16px">
            <tbody>
              {tableEntries.map(
                ({ label, value, url, email: tableEntryEmail }, index) => (
                  <tr key={index}>
                    <td>{label}</td>
                    <td align="right">
                      {!url && !tableEntryEmail && value}
                      {url && !tableEntryEmail && (
                        <StyledLink href={url} target="_blank">
                          {value}
                        </StyledLink>
                      )}
                      {tableEntryEmail && (
                        <StyledLink
                          href={`mailto:${tableEntryEmail}?subject=${
                            emailSubject ?? ""
                          }`}
                          target="_blank"
                          icon={<MailOutline sx={{ ml: 1 }} />}
                        >
                          {value}
                        </StyledLink>
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <Box sx={{ flex: "1 1 100%" }}></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 6,
            }}
          >
            <Button
              href={url}
              target="_blank"
              sx={{
                background: "#F89500",
                color: "#ffffff",
                borderRadius: "5px",
                "&:hover": {
                  background: "#F89500",
                  filter: "brightness(105%)",
                  color: "#ffffff",
                },
              }}
            >
              Open
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={isInfoModalOpen}
        fullWidth
        onClose={() => setIsInfoModalOpen(false)}
      >
        <IconButton
          sx={{ position: "absolute", right: 10, top: 10 }}
          onClick={() => setIsInfoModalOpen(false)}
        >
          <Close />
        </IconButton>
        <DialogTitle>{name}</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>{infoText}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

const StyledLink = (
  props: PropsWithChildren<{
    href: string;
    target?: string;
    icon?: JSX.Element;
  }>
) => (
  <a
    {...props}
    style={{
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
      color: "#003781",
      textDecoration: "none",
      position: "relative",
      left: 4,
    }}
  >
    <Typography>{props.children}</Typography>
    {props.icon || <ArrowForward sx={{ ml: 1 }} />}
  </a>
);

export default AppInfoCard;
