import { Grid } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { AppInfoCard, AppInfoCardProps } from "@pimo/pimo-components";

interface AppInfoCardsProps {
  apps: AppInfoCardProps[];
}

export const AppInfoCards: PimoReactComponent<AppInfoCardsProps> = ({
  apps = [],
}) => {
  return (
    <Grid container spacing={2}>
      {apps.map((app) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={app.name}>
          <AppInfoCard {...app} />
        </Grid>
      ))}
    </Grid>
  );
};
