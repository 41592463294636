import { Cache } from "@pimo/pimo-app-builder";
import {
  PaginatedStrapiResponse,
  StrapiCollectionEntry,
} from "@pimo/strapi-types-and-utils";
import { STRAPI_URL } from "../env";
import { App } from "isrm-home-portal-types";

const cache = new Cache();

export async function fetchApps(): Promise<App[]> {
  try {
    const response = await cache.fetch(`${STRAPI_URL}/api/apps?populate=*`, {});

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as PaginatedStrapiResponse<
      StrapiCollectionEntry<App[]>
    >;

    return json.data.map((entry) => entry.attributes) as unknown as App[];
  } catch {
    return [];
  }
}
